/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Bruxelles"), React.createElement("p", null, "Pour se loger, Bruxelles offre quatre principales possibilités :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/be/brussels/brusselshistoricalcenter.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le centre historique"), " : appelé Pentagone, il est en grande partie réservé aux piétons."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/be/brussels/europeandistrict.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Le quartier européen"), ", à l’est : le Parlement européen et les institutions de l’UE, autour du parc Léopold."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/be/brussels/elsene.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Ixelles"), ", au sud-est : accessible via la porte de Namur, une commune où chaque quartier a son identité."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/be/brussels/sint-gillis.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Saint-Gilles"), ", au sud-ouest : près de la gare de Bruxelles-Midi, une commune en pleine gentrification."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
